import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from './button';
import MainLogo from './mainLogo';

function AppHeader() {
    const buttonDefaultName = "Sign Up for Waitlist";
    const [buttonNameValue, setButtonNameValue] = useState(buttonDefaultName);

    const handleClick = async () => {
        setButtonNameValue("Loading ...");
        setButtonNameValue(buttonDefaultName);
      };


  return (
    <Container color='while'>
        <Row>
            <Col>
                <MainLogo />
            </Col>

            <Col>
            <Button onClick={handleClick}>
                {buttonNameValue}
            </Button>
            </Col>

        </Row>
    </Container>
  );
}

export default AppHeader;