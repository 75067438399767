import React, { useState, useEffect } from 'react';
import Header from "./components/header"
import './styles/styles.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div style={{ backgroundColor: '#1a1a1a' }}>
      <Header />
      <div className='title'>
        <text className='appName'>
          Fully autonomous email newsletter platform for online publishers and bloggers.
        </text>
      </div>
    </div>
  );
};

export default App;
